import React, { useState } from "react";
import "./TicketDetails.css";
import { RenderHtml1 } from "../../utils/LocalFunctions";
import ImagePreview from "../image-preview/ImagePreview";

function TicketChat(props) {
  const { issue, refreshTicketChat, setRefreshTicketChat, ticketChatNote } =
    props;
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
console.log("show");

  return (
    ticketChatNote?.ticket_messages?.length > 0 && (
      <div className="ticket-chat-main-container-details text-left">
        <h6>Ticket Chat</h6>
        {ticketChatNote?.ticket_messages?.map((chat, i) => (
          <div
            key={i}
            className="single-ticket-chat-details-container flex-start flex-column"
            style={{
              backgroundColor:
                chat?.source === "AGENT" ? "lightblue" : "lightgreen",
            }}
          >
            <p
              className="width-100 "
              style={{
                textAlign: "left",
                paddingBottom: "10px",
                fontWeight: 600,
                color: chat?.source === "AGENT" ? "#821131" : "#0D92F4",
              }}
            >
              {chat?.user_name}{" "}
              <span className="font-10px">{`( ${chat?.created_at} )`}</span>
            </p>

            <div className="width-100">{RenderHtml1(chat?.message)}</div>
            {chat?.image_url && (
              <div
                className="image-wrapper-ticket-chat cursor-p"
                onClick={() => {
                  setPreviewImageUrl(chat?.image_url);
                  setShowImagePreview(true);
                }}
              >
                <img
                  src={chat?.image_url ? chat?.image_url : ""}
                  alt="image-chat"
                  className="height-100 width-100"
                />
              </div>
            )}
          </div>
        ))}

        {showImagePreview && (
          <ImagePreview
            show={showImagePreview}
            onHide={() => setShowImagePreview(false)}
            url={previewImageUrl}
          />
        )}
      </div>
    )
  );
}

export default TicketChat;
