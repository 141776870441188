import React, { useEffect, useRef, useState } from "react";
import "./SelectionModal.css";
import { Modal } from "react-bootstrap";
import { crossBlack } from "../../../utils/SvgIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { get_api, parsevalue } from "../../../utils/LocalFunctions";
import { cms_api } from "../../chat-bot/AiAssistanceBot";
import { headers } from "../../../App";

export function SearchableDropDown({
  options,
  key_to_render,
  onClickHandler,
  type,
  escalate_close,
  data,
  isShippingList,
  orderShipments,
}) {
  const [open_options, setOpenOptions] = useState(false);
  const dropdownRef = useRef(null);
  const [search_string, setSearchString] = useState("");

  // Handle clicks outside of the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      ref={dropdownRef}
      className="searchbale-select-container-main-container width-100"
    >
      <div
        style={{
          height: "40px",
          borderBottom: open_options ? "1px solid #ccc" : "none",
        }}
        className="flex-space-between-center searchbale-select-container width-100"
      >
        <input
          onFocus={() => {
            setOpenOptions(true);
          }}
          value={search_string}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          style={{
            fontSize: escalate_close && "13px",
          }}
          type="text"
          className="width-100 border-none"
        />
        <FontAwesomeIcon icon={open_options ? faAngleUp : faAngleDown} />
      </div>
      {open_options && (
        <div className="searchbale-select-container-options">
          {orderShipments && (
            <div
              className="hover-content "
              onClick={() => {
                if (escalate_close) {
                  setSearchString("NA");
                  setOpenOptions(false);
                  onClickHandler("NA");
                }
              }}
            >
              {"N/A"}
            </div>
          )}
          {isShippingList
            ? options
                ?.filter((e) => e?.includes(search_string))
                ?.map((e, i) => {
                  return (
                    <div
                      className="hover-content "
                      onClick={() => {
                        // if (escalate_close) {
                        //   setSearchString(
                        //     type === "ORDER"
                        //       ? `${e?.is_trial ? "TRIAL" : "SHOP"} #${
                        //           e.id
                        //         } - ${e?.order_status?.toUpperCase()} ${
                        //           e.user_name
                        //         } ${e?.created.split(" ")[0]} Rs.${e?.total}`
                        //       : `${e?.id}:${e[key_to_render]}`
                        //   );
                        //   setOpenOptions(false);
                        // }
                        if (escalate_close) {
                          setSearchString(e);
                          setOpenOptions(false);
                          onClickHandler(e);
                        }
                      }}
                      key={i}
                    >
                      {e}
                    </div>
                  );
                })
            : options
                ?.filter((e) =>
                  (e[key_to_render] + e.id)
                    ?.toLowerCase()
                    ?.includes(search_string.toLowerCase())
                )
                ?.map((e, i) => {
                  return (
                    <div
                      className="hover-content "
                      onClick={() => {
                        if (escalate_close) {
                          setSearchString(
                            type === "ORDER"
                              ? `${e?.is_trial ? "TRIAL" : "SHOP"} #${
                                  e.id
                                } - ${e?.order_status?.toUpperCase()} ${
                                  e.user_name
                                } ${e?.created.split(" ")[0]} Rs.${e?.total}`
                              : `${e?.id}:${e[key_to_render]}`
                          );
                          setOpenOptions(false);
                        }
                        onClickHandler(e);
                      }}
                      key={i}
                    >
                      {type === "ORDER"
                        ? `${e?.is_trial ? "TRIAL" : "SHOP"} #${
                            e.id
                          } - ${e?.order_status?.toUpperCase()}  ${
                            e.user_name
                          }  ${e?.created.split(" ")[0]}  Rs.${e?.total}`
                        : `${e?.id}:${e[key_to_render]}`}
                    </div>
                  );
                })}
        </div>
      )}
    </div>
  );
}

export default function SelectionModal(props) {
  const {
    onHide,
    selectionList,
    refund_list,
    return_request,
    user_order_list,
    text,
    setChatText,
  } = props;

  const [localselectionList, setLocalSelectionList] = useState(
    parsevalue([...selectionList])
  );
  const [loader, setLoader] = useState(false);

  const api = get_api();
  const saveclickHandler = () => {
    if (!loader) {
      setLoader(true);
      axios
        .post(`${api}/ai_inhancement/`, {
          text: text,
          context: localselectionList,
          action: "CONTEXTUALIZE",
        })
        .then((res) => {
          setChatText(res.data?.enhanced_text);
          setLocalSelectionList([]);
          onHide();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  function get_order_details(e) {
    return new Promise((resolve, reject) => {
      if (e.is_trial) {
        axios
          .get(`${cms_api}/discover_order/cms/order/${e.id}`, {
            headers,
          })
          .then((res) => {
            resolve(res.data.content);
          })
          .catch((r) => {
            reject(r);
          });
      } else {
        axios
          .post(
            `${cms_api}/discover_order/cms/shop_order/detail/${e.id}`,
            {},
            { headers }
          )
          .then((res) => {
            resolve(res.data.content);
          })
          .catch((r) => {
            reject(r);
          });
      }
    });
  }

  function get_return_request_details(e) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${cms_api}/discover_order/cms/order_return/return_request/${e.id}`,
          { headers }
        )
        .then((res) => {
          resolve(res.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  function get_refund_request_details(e) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${cms_api}/discover_order/cms/order_return/return_request/${e.request_id}`,
          { headers }
        )
        .then((res) => {
          resolve(res.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="template-modal-conatiner height-100"
    >
      <div className="templates-modal height-100 ">
        <div className="templates-modal-top-container flex-space-between">
          <p className="font-weight-600">{"AI Assistance"}</p>
          <span onClick={onHide} className="cursor-p">
            {crossBlack}
          </span>
        </div>

        <div
          style={{ height: "calc(100% - 100px)", overflowY: "scroll" }}
          className="width-100 mt-24px"
        >
          {localselectionList?.map((e, i) => {
            return (
              <label
                key={i}
                style={{ gap: "16px", marginBottom: "24px" }}
                className="width-100 flex-flex-start-flex-start"
              >
                <p className="mt-8px" style={{ width: "200px" }}>
                  {e?.message}
                </p>
                <div className="mt-8px" style={{ width: "calc(100% - 200px)" }}>
                  {e?.value ? (
                    <div>
                      {e.selection_type === "ORDER"
                        ? e?.value?.order_type === "Trial"
                          ? e?.value?.order_id
                          : e?.value?.id
                        : e?.value?.return_request_id}
                      :{e?.value?.user_name}
                      <FontAwesomeIcon
                        onClick={() => {
                          let obj = localselectionList[i];
                          delete obj.value;
                          localselectionList[i] = obj;
                          setLocalSelectionList(
                            parsevalue([...localselectionList])
                          );
                        }}
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        icon={faXmark}
                      />
                    </div>
                  ) : (
                    <SearchableDropDown
                      options={
                        e.selection_type === "ORDER"
                          ? user_order_list
                          : e.selection_type === "RETURN_REQUEST"
                          ? return_request
                          : refund_list
                      }
                      type={e.selection_type}
                      key_to_render={"user_name"}
                      onClickHandler={async (sel_obj) => {
                        let obj = localselectionList[i];
                        if (e.selection_type === "ORDER") {
                          let order_deatils = {};
                          await get_order_details(sel_obj)
                            .then((res) => {
                              order_deatils = res;
                            })
                            .catch((err) => {});

                          obj["value"] = order_deatils;
                        } else if (e.selection_type === "RETURN_REQUEST") {
                          let return_request = {};
                          await get_return_request_details(sel_obj)
                            .then((res) => {
                              return_request = res;
                            })
                            .catch((err) => {});
                          obj["value"] = return_request;
                        } else {
                          let refund_request = {};
                          await get_refund_request_details(sel_obj)
                            .then((res) => {
                              refund_request = res;
                            })
                            .catch((err) => {});
                          obj["value"] = refund_request;
                        }
                        localselectionList[i] = obj;
                        setLocalSelectionList(
                          parsevalue([...localselectionList])
                        );
                      }}
                    />
                  )}
                </div>
              </label>
            );
          })}
        </div>
        <div className="width-100 flex-flex-end-center">
          {loader && (
            <div class="spinner-border text-primary mr-20px" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )}
          <div
            onClick={() => {
              saveclickHandler();
            }}
            className="purple-btn"
          >
            Save
          </div>
        </div>
      </div>
    </Modal>
  );
}
