import React, { useState, useEffect, useRef } from "react";
import CustomSingleSelectDropdown from "../../custom-single-select-dropdown/CustomSingleSelectDropdown";
import Modal from "react-bootstrap/Modal";
import "./EndChatModal.css";
import { crossBlack } from "../../../utils/SvgIcons";
import axios from "axios";
import { headers } from "../../../App";
import { get_api } from "../../../utils/LocalFunctions";
import { SearchableDropDown } from "../selection_modal/SelectionModal";
import { cms_api } from "../../chat-bot/AiAssistanceBot";

const EndChatModal = (props) => {
  const {
    onHide,
    apiCall,
    chatSessionId,
    isLoading,
    setIsLoading,
    TicketResponse,
    ticekt_id,
    message,
    setChatText,
    fetchTickets,
    selectedStatus,
    issue,
    setRefreshTicketChat,
    bulk_tickets,
    escalate_close,
    user_order_list,
    chatSubsessionId,
    isShippingList,
  } = props;
  const [closingCategory, setClosingCategory] = useState("");
  const [reason, setReason] = useState("");
  const [subReason, setSubReason] = useState("");
  const [conversationNotes, setConversationNotes] = useState("");
  const [endChatOptions, setEndChatOptions] = useState([]);
  const [filteredReasons, setFilteredReasons] = useState([]);
  const [filteredSubReasons, setFilteredSubReasons] = useState([]);
  const [user_message, setUserMessage] = useState("");
  const [errors, setErrors] = useState({});

  const closingCategoryRef = useRef(null);
  const reasonRef = useRef(null);
  const subReasonRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const [data, setData] = useState({
    selectOrder: "",
    orderRef: useRef(null),
    order_json: {},
    selectShipmentId: null,
  });

  const UpdateMainData = (type, value) => {
    let copy = data;
    copy[type] = value;
    setData({ ...copy });
  };

  // Handler to toggle checkbox state
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const api = get_api();
  const sessionId = localStorage.getItem("cms_session_id");

  useEffect(() => {
    const shoproute = `${cms_api}/discover_order/cms/${
      data?.selectOrder?.is_trial ? "order" : "shop_order"
    }/detail/${data?.selectOrder?.id}`;
    const trailRoute = `${cms_api}/discover_order/cms/${
      data?.selectOrder?.is_trial ? "order" : "shop_order"
    }/${data?.selectOrder?.id}`;

    if (Object.keys(data?.selectOrder).length > 0) {
      if (data?.selectOrder?.is_trial) {
        axios
          .get(trailRoute, {
            headers,
          })
          .then((res) => {
            UpdateMainData("order_json", res?.data?.content);
            console.log("idd", res?.data?.content);

            UpdateMainData("selectShipmentId", res?.data?.content?.tracking_id);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        axios
          .post(shoproute, {}, { headers })
          .then((res) => {
            UpdateMainData("order_json", res?.data?.content);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }, [data?.selectOrder]);

  useEffect(() => {
    if (escalate_close) {
      axios
        .get(`${api}/escalationcategory/`)
        .then((res) => {
          setEndChatOptions(res?.data?.escalation_categories || []);
        })
        .catch((error) => {
          console.log(error?.message);
        });
    } else {
      axios
        .get(`${api}/closingcategory/`)
        .then((res) => {
          setEndChatOptions(res?.data?.closing_categories || []);
        })
        .catch((error) => {
          console.log(error?.message);
        });
    }
  }, []);

  useEffect(() => {
    if (closingCategory) {
      const selectedCategory = endChatOptions?.find(
        (option) => option.name === closingCategory
      );

      if (selectedCategory?.reasons?.length > 0) {
        setFilteredReasons([...selectedCategory?.reasons]);
      } else {
        setFilteredReasons([]);
      }

      // Reset reason and sub-reason when closing category changes
      setReason("");
      setSubReason("");
    }
  }, [closingCategory, endChatOptions]);

  useEffect(() => {
    if (reason) {
      const selectedReason = filteredReasons?.find(
        (option) => option.name.toLowerCase() === reason.toLowerCase()
      );

      if (selectedReason?.sub_reasons?.length > 0) {
        setFilteredSubReasons([...selectedReason?.sub_reasons]);
      } else {
        setFilteredSubReasons([]);
      }
      setSubReason("");
    }
  }, [reason, filteredReasons]);

  console.log("select", data);

  function sendOrderPayload() {
    const id = data?.selectOrder?.id;
    const isTrial = data?.selectOrder?.is_trial;
    if (id !== undefined) {
      return `${isTrial ? "Trial" : "Shop"} order #${id}`;
    } else {
      return "NA";
    }
  }

  async function putCallEndChat() {
    setIsLoading(true);
    try {
      if (escalate_close) {
        const response = await axios.post(`${api}/escalationcategory/`, {
          category: closingCategory,
          reason: reason,
          sub_reason: subReason,
          order_id: sendOrderPayload(),
          order_date: data?.selectOrder?.created,
          chat_sub_session_id: chatSubsessionId,
          note: conversationNotes,
          order_json: data?.order_json,
          awb: data?.selectShipmentId ? data?.selectShipmentId : null,
        });

        if (response) {
          setReason("");
          setSubReason("");
          setIsLoading(false);
          onHide();
        }
      } else {
        const response = await axios.put(
          `${api}/chatsession/${chatSessionId}/`,
          {
            closing_category: closingCategory,
            closing_reason: reason,
            closing_sub_reason: subReason,
            closing_notes: conversationNotes,
            is_delegated: isChecked,
          }
        );

        if (response) {
          setReason("");
          setSubReason("");
          apiCall("end chat");
          setIsLoading(false);
          onHide();
        }
      }
    } catch (error) {
      console.error(error?.message);

      setIsLoading(false);
    }
  }

  function sendStatusPayload() {
    if (issue?.status === "PENDING") {
      return {
        status: selectedStatus ? selectedStatus : "RESOLVED",
        closing_category: closingCategory,
        closing_reason: reason,
        closing_sub_reason: subReason,
        closing_notes: conversationNotes,
        user_message: message,
      };
    } else {
      return {
        cms_status: selectedStatus ? selectedStatus : "RESOLVED",
        closing_category: closingCategory,
        closing_reason: reason,
        closing_sub_reason: subReason,
        closing_notes: conversationNotes,
        user_message: message,
      };
    }
  }

  function sendticketresponse() {
    setIsLoading(true);
    axios
      .put(`${api}/tickets/${ticekt_id}/`, sendStatusPayload())
      .then((res) => {
        onHide();
        setChatText("");
        setRefreshTicketChat(true);
        fetchTickets();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const validateForm = () => {
    const newErrors = {};

    if (!closingCategory) {
      newErrors.closingCategory = "Closing category is required.";
    }

    if (!reason) {
      newErrors.reason = "Reason is required.";
    }

    if (!subReason) {
      newErrors.subReason = "Sub-reason is required.";
    }

    if (escalate_close) {
      if (!data?.selectOrder) {
        newErrors.selectOrder = "Order is required.";
      }

      if (data?.selectOrder?.is_trial === false) {
        if (!data?.selectShipmentId) {
          newErrors.selectShipmentId = "Shipment id is required.";
        }
      }
    }

    if (!user_message && bulk_tickets) {
      newErrors.user_message = "User message is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  console.log("errors", errors);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (bulk_tickets) {
      if (validateForm()) {
        apiCall({
          closingCategory,
          reason,
          subReason,
          conversationNotes,
          user_message,
        });
      }
    } else if (TicketResponse) {
      if (!isLoading && validateForm()) {
        sendticketresponse();
      }
    } else if (!isLoading && validateForm()) {
      putCallEndChat();
    }
  };

  const shipments = data?.order_json?.packages?.map(
    (e) => e?.shipments?.tracking_id
  );

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <div className="end-chat-modal-modified-content">
        <div className="flex-space-between pb-8px">
          <p className="font-weight-600">
            {escalate_close ? "Escalate" : "End chat"}
          </p>
          <p onClick={onHide} className="cursor-p">
            {crossBlack}
          </p>
        </div>
        <div className="flex-column">
          <div className="dropdown-row">
            <div className="flex-column">
              <CustomSingleSelectDropdown
                ref={closingCategoryRef}
                label="Closing Category"
                options={endChatOptions.map((option) => option.name)}
                value={closingCategory}
                setvalue={setClosingCategory}
                handleClick={(option) => {
                  setClosingCategory(option);
                  setReason("");
                  setSubReason("");
                  if (reasonRef.current) {
                    reasonRef.current.focus();
                  }
                }}
              />
              {errors.closingCategory && closingCategory === "" && (
                <p className="error-message">{errors.closingCategory}</p>
              )}
            </div>
            <div className="flex-column">
              <CustomSingleSelectDropdown
                ref={reasonRef}
                label="Reason"
                options={filteredReasons.map((option) => option.name)}
                value={reason}
                setvalue={setReason}
                handleClick={(option) => {
                  setReason(option);
                  setSubReason("");
                  if (subReasonRef.current) {
                    subReasonRef.current.focus();
                  }
                }}
              />
              {errors.reason && reason === "" && (
                <p className="error-message">{errors.reason}</p>
              )}
            </div>
          </div>
          <div className="dropdown-row">
            <div className="flex-column">
              <CustomSingleSelectDropdown
                ref={subReasonRef}
                label="Sub-reason"
                options={filteredSubReasons}
                value={subReason}
                setvalue={setSubReason}
                handleClick={(option) => setSubReason(option)}
              />
              {errors.subReason && subReason === "" && (
                <p className="error-message">{errors.subReason}</p>
              )}
            </div>
            <div className="conversation-notes-container">
              <label htmlFor="conversation-notes" className="dropdown-label">
                Conversation notes (optional)
              </label>
              <input
                id="conversation-notes"
                type="text"
                className="conversation-notes-input"
                value={conversationNotes}
                onChange={(e) => setConversationNotes(e.target.value)}
                placeholder="Enter the notes here, if any"
                autoComplete="off"
              />
            </div>
          </div>
          {bulk_tickets && (
            <label className="width-100 mb-20px">
              <input
                type="text"
                className="width-100  conversation-notes-input"
                placeholder="Enter Message"
                value={user_message}
                onChange={(e) => {
                  setUserMessage(e.target.value);
                }}
              />
              {errors.user_message && user_message === "" && (
                <p className="error-message">{errors.user_message}</p>
              )}
            </label>
          )}

          {escalate_close && (
            <div
              style={{
                marginBottom: "15px",
              }}
            >
              <p
                style={{
                  textWrap: "nowrap",
                  paddingBottom: "5px",
                }}
                className="dropdown-label"
              >
                Select Order
              </p>
              <SearchableDropDown
                options={user_order_list}
                // type={e.selection_type}
                key_to_render={"user_name"}
                onClickHandler={async (sel_obj) => {
                  UpdateMainData("selectOrder", sel_obj);
                }}
                escalate_close={true}
                data={data?.selectOrder}
                orderShipments={true}
              />

              {errors.selectOrder && data?.selectOrder === "" && (
                <p className="error-message">{errors.selectOrder}</p>
              )}
            </div>
          )}

          {isShippingList &&
            data?.selectOrder?.is_trial === false &&
            shipments?.length > 0 && (
              <div
                style={{
                  marginBottom: "15px",
                }}
              >
                <p
                  style={{
                    textWrap: "nowrap",
                    paddingBottom: "5px",
                  }}
                  className="dropdown-label"
                >
                  Select Shipment
                </p>
                <SearchableDropDown
                  options={data?.order_json?.packages?.map(
                    (e) => e?.shipments?.tracking_id
                  )}
                  // type={e.selection_type}
                  key_to_render={"user_name"}
                  onClickHandler={async (id) => {
                    UpdateMainData("selectShipmentId", id);
                  }}
                  escalate_close={true}
                  data={data?.selectShipmentId}
                  isShippingList={true}
                />

                {errors.selectShipmentId && data?.selectShipmentId === null && (
                  <p className="error-message">{errors.selectShipmentId}</p>
                )}
              </div>
            )}

          <div
            className={`ooter-next-chat ${
              bulk_tickets ? "flex-flex-end-center" : "flex-space-between"
            }`}
          >
            {bulk_tickets ? null : !TicketResponse && !escalate_close ? (
              <label className="flex-center-center">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  style={{
                    marginRight: "5px",
                    height: "30px",
                  }}
                />
                Escalate
              </label>
            ) : escalate_close ? (
              <p></p>
            ) : (
              <p className="get-next-chat">Get Next Chat</p>
            )}

            <div className="buttons-container">
              <button
                className="cancel-btn"
                onClick={onHide}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="chat-completed-btn"
                disabled={isLoading}
              >
                {escalate_close ? "Escalate" : "Chat Completed"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EndChatModal;
