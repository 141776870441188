import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ImagePreview.css";
import { Close } from "@mui/icons-material";

function ImagePreview(props) {
  const { url, onHide, show } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="image-preview-modal-container width-100 height-100"
    >
      <div className="wrapper-image-preview flex-column width-100 height-100 hide-scroll">
        <p onClick={onHide} className="cross-icon-image">
          <Close />
        </p>
        <div className="width-100 height-100 image-preview-modal-content">
          <img src={url} alt="url" className="width-100 height-100" />
        </div>
      </div>
    </Modal>
  );
}

export default ImagePreview;
