import React, { useState, useEffect, useRef } from "react";
import "./ChatBot.css";
import ChatInterface, { message_type } from "../chatInterface/ChatInterface";
import {
  cleanHtml,
  convertHtmlToText,
  convertTextToHtml,
  get_api,
  parsevalue,
  redirectToPath,
} from "../../utils/LocalFunctions";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "../ticket-details/TicketDetails.css";
import axios from "axios";
import ChatModal from "../modals/chat-modal/ChatModal";
import TemplatesModal from "../modals/templates-modal/TemplatesModal";
import EndChatModal from "../modals/end-chat-modal/EndChatModal";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { convertToTime } from "../../utils/utils";
import { Popover } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import SelectionModal from "../modals/selection_modal/SelectionModal";
import { useWebSocket } from "../../utils/Socket";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DELEGATE_CHAT, MAKE_CALL, permissions } from "../../utils/permissions";
import { headers } from "../../App";
import { InfoICON } from "../../utils/SvgIcons";
import { formatTimeStamp } from "../../utils";
import { Tooltip } from "antd";
import CallUserModal from "../modals/call-user-modal/CallUserModal";
import { callDetailsToolTipContent } from "../ticket-details/TicketDetails";
import ConfirmationModal from "../modals/confirmation-modal/ConfirmationModal";
import Toaster from "../toaster/Toaster";
import { Spinner } from "react-bootstrap";

export const cms_api = "https://route.smytten.com";

export const modules = {
  toolbar: [["bold", "italic", "underline"]],
};

export const topWidgetsList = [
  {
    value: "CLOSE_CHAT",
    name: "End chat",
    permission: "ALL",
  },
  {
    value: "UPLOAD_IMAGE",
    name: "Image upload",
    permission: "ALL",
  },

  {
    value: "templates",
    name: "Templates",
    permission: "ALL",
  },
  {
    value: "delegation",
    name: "Delegation",
    permission: DELEGATE_CHAT,
  },
  {
    value: "escalate",
    name: "Escalate",
    permission: "ALL",
  },
  {
    value: "image_required",
    name: "Upload Image",
    permission: "",
  },
];

export const ai_enhancement = [
  {
    label: "Rephrase",
    value: "Rephrase the message to be more customer friendly",
  },
  {
    label: "Make Shorten",
    value: "Shorten the message to be more customer friendly",
  },
  {
    label: "Make Polite",
    value: "Make the message tone a little softer",
  },
  {
    label: "Make Harder",
    value: "Make the message tone a little stern",
  },
  {
    label: "Make More Descriptive",
    value: "Make the message more descriptive",
  },
];

function AiAssistance(props) {
  const {
    chatSessionId,
    can_user_input,
    chatSubsessionId,
    get_chats,
    chatList,
  } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  //6242244
  const chat_user_id = searchParams.get("user_id");
  const [timer, setTimer] = useState(0);
  const {
    subscribeToGroup,
    sendMessagetoGrop,
    unsubscribeFromGroup,
    showSuccessToast,
  } = useWebSocket();
  const [chat_data, setChatData] = useState(null);
  const [chat_history, setChatHistory] = useState([]);
  const allParams = {
    bot_id: 21,
    session_id: "4093c697-1587-4df6-b434-4335483f01f4",
    user_id: chat_user_id,
    user_name: "Swathy",
    current_chat_id: chatSessionId,
    sender_type: "USER",
    proxy_name: localStorage.getItem("proxy_name"),
    is_agent: true,
  };
  const api = get_api();
  const [chat_text, setChatText] = useState(
    localStorage.getItem(chatSessionId)
      ? localStorage.getItem(chatSessionId)
      : ""
  );

  const parentchatContainerRef = useRef(null);
  const chatTextArea = useRef(null);
  const [chatmodals, setChatModals] = useState({
    modal: false,
    value: "",
    text: "",
  });
  const [allTemplates, setAllTemplates] = useState([]);

  const [recomendedTempltes, setRecomendedtemplates] = useState({
    name: "",
    list: [],
  });
  const [toggleOptions, setToggleOptions] = useState(false);

  const [refund_list, setRefundList] = useState([]);
  const [return_request, setReturnRequest] = useState([]);
  const [user_order_list, setUserOrderList] = useState([]);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [loader, setLoader] = useState(false);
  const [chat_loader, setChatLoader] = useState(false);
  const [ticketCount, setTicketCount] = useState({});

  const [selectionList, setSelectionList] = useState([]);

  const [selection_modal, setSelectionModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    openCallUserModal: false,
    callUserData: [],
    openBlockUserModal: false,
    isApi: false,
    showtoast: false,
    showTooltip: false,
  });

  const UpdateMainData = (type, value) => {
    let copy = data;
    copy[type] = value;
    setData({ ...copy });
  };

  const userId = searchParams.get("user_id");

  const ticketView = searchParams.get("ticket_view");

  const abortControllers = useRef(new Map());

  const cancelPreviousCall = (key) => {
    const controller = abortControllers.current.get(key);
    if (controller) {
      controller.abort(); // Cancel ongoing request
      abortControllers.current.delete(key); // Remove controller from map
    }
  };

  const createAbortController = (key) => {
    cancelPreviousCall(key); // Cancel any existing call for the key
    const abortController = new AbortController();
    abortControllers.current.set(key, abortController); // Store new controller
    return abortController;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleScroll() {
    const { scrollTop, scrollHeight, clientHeight } =
      parentchatContainerRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 40) {
      setIsUserScrolling(false);
    } else {
      setIsUserScrolling(true);
    }
  }

  useEffect(() => {
    if (data?.showtoast) {
      const time = setTimeout(() => {
        UpdateMainData("showtoast", false);
      }, 3000);
      return () => {
        clearTimeout(time);
      };
    }
  }, [data?.showtoast]);

  async function getCallUserData() {
    try {
      const response = await axios.get(
        `${api}/makecall/?user_id=${searchParams.get("user_id")}`
      );

      UpdateMainData("callUserData", response?.data);
    } catch (e) {
      console.log(e);
    }
  }

  const tooltipRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        UpdateMainData("showTooltip", false); // Close the tooltip
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  useEffect(() => {
    setIsUserScrolling(false);
  }, [chatSessionId]);

  useEffect(() => {
    const container = parentchatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chatSessionId]);

  useEffect(() => {
    const key = `${chatSessionId}-${"ticketView"}`;
    const abortController = createAbortController(key);
    axios
      .get(`${api}/user_ticket/?user_id=${chat_user_id}`, {
        signal: abortController.signal,
      })
      .then((res) => {
        setTicketCount(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [ticketView, chatSessionId]);

  function scrollChatToBottom(flag) {
    if ((!isUserScrolling && parentchatContainerRef.current) || flag) {
      parentchatContainerRef.current.scrollTo({
        top: parentchatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  useEffect(() => {
    axios
      .get(`${api}/template/`)
      .then((res) => {
        setAllTemplates(res?.data?.content);
      })
      .catch((e) => {
        console.log(e?.message);
      });

    axios
      .post(
        `${cms_api}/discover_order/cms/order_return/refund_list`,
        {
          page: {
            page_id: 0,
            limit: 50,
          },
          search: chat_user_id,
          filter_by: {
            status: "cancel",
            order_type: "both",
            from_date: "",
            to_date: "",
          },
        },
        { headers }
      )
      .then((res) => {
        setRefundList(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${cms_api}/discover_order/cms/order/user_order_list`,
        { user_id: chat_user_id },
        {
          headers,
        }
      )
      .then((res) => {
        setUserOrderList(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .post(
        `${cms_api}/discover_order/cms/order_return/return_request`,
        {
          page: { page_id: 0, limit: 50 },
          search: chat_user_id,
          filter_by: {
            status: "ALL",
            order_type: "both",
            from_date: "",
            to_date: "",
          },
        },
        {
          headers,
        }
      )
      .then((res) => {
        setReturnRequest(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchParams]);

  const getchatHistory = (flag) => {
    const key = `getChatHistory-${chatSessionId}`;
    const abortController = createAbortController(key);
    if (flag === true) {
      setChatLoader(true);
    }
    axios
      .get(`${api}/chatsession/${chatSessionId}/?source=BOT`, {
        signal: abortController.signal,
      })
      .then((res) => {
        // make_a_again(res?.data?.content?.auto_refresh_delay, chat_session);
        // console.log("ai", res?.data?.content);
        if (flag) {
          setChatLoader(false);
        }
        setChatData(res.data?.content);
        setChatHistory(res?.data?.content?.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const chat_id = `CHAT_SESSION_${chatSessionId}`;
    subscribeToGroup(chat_id, (message) => {
      const currentChatSessionId = window.location.pathname.split("/")[2];
      if (message?.sender === "BOT") {
        // showSuccessToast();
        get_chats();
      }
      if (currentChatSessionId === chatSessionId) {
        getchatHistory();
      }
    });
    return () => {
      // unsubscribeFromGroup(chat_id);
    };
  }, [chatSessionId]);

  useEffect(() => {
    setChatHistory([]);
    if (chatSessionId) {
      getchatHistory(true);
    }
    const currentControllers = abortControllers.current;
    return () => {
      currentControllers.forEach((controller) => controller.abort());
      currentControllers.clear();
    };
  }, [chatSessionId]);

  useEffect(() => {
    setTimeout(() => {
      scrollChatToBottom();
    }, 100);
  }, [chat_history]);

  function sendMessage(message) {
    const userMessage = message?.return_value;

    if (userMessage !== "") {
      if (message) {
        if (message?.dont_show) {
        } else {
          setChatHistory([...chat_history, { ...message, sender_type: "BOT" }]);
        }
      }

      let paramsObj = { ...allParams };
      paramsObj.user_message = userMessage;
      paramsObj.selected_options = message?.options ? message?.options : [];
      paramsObj.type = message?.type ? message?.type : message_type?.message;
      paramsObj.source = "BOT";

      if (message.type === "IMAGE") {
        paramsObj.user_message = message.user_message;
        paramsObj.image_url = message.image_url;
      }

      axios
        .put(`${api}/chatsession/${paramsObj?.current_chat_id}/`, paramsObj)
        .then((data) => {
          setChatModals({
            modal: false,
            value: "",
            text: "",
          });

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    localStorage.setItem(chatSessionId, chat_text);

    // return () => {
    //   localStorage.removeItem(chatSessionId);
    // };
  }, [chat_text]);

  useEffect(() => {
    setChatText(
      localStorage.getItem(chatSessionId)
        ? localStorage.getItem(chatSessionId)
        : ""
    );
  }, [chatSessionId]);

  const handleSend = () => {
    const value = cleanHtml(chat_text.trim());
    // console.log(value);
    // return;
    if (value === "") return;
    sendMessage({
      message: value,
      return_value: value,
      type: message_type?.message,
    });
    setChatText("");
    setRecomendedtemplates({
      name: "",
      list: [],
    });
  };

  function defaultChatText(element) {
    if (element.value === "CLOSE_CHAT") {
      return "End chat";
    } else if (element.value === "UPLOAD_IMAGE") {
      return "Image upload";
    } else if (element.value === "FEEDBACK_REQUEST") {
      return "Request feedback";
    } else {
      return "";
    }
  }

  function handleClickWidjet(element) {
    setChatModals((prev) => ({
      modal: true,
      value: element.value,
      text: defaultChatText(element),
    }));
  }
  function searchByCategory(searchString) {
    // Filter items whose categories include the search string as part of any category
    const filteredList = allTemplates.filter((item) =>
      item?.categories?.some((category) =>
        category.toLowerCase().includes(searchString.toLowerCase())
      )
    );

    // Return the object with the search string as the name and the filtered list
    return {
      name: searchString,
      list: filteredList,
    };
  }

  function last_characters(text) {
    if (text.length > 1) {
      return text[text.length - 2] + text[text.length - 1] === "/t";
    } else {
      return false;
    }
  }

  const handleKeyDown = (e) => {
    e.stopPropagation();
    const value = convertHtmlToText(e?.target?.innerHTML);
    if (last_characters(value)) {
      setChatModals(() => ({
        modal: true,
        value: "templates",
        text: "",
      }));
    }
    if (value.length > 2 && value.split(" ").length === 1) {
      let obj = searchByCategory(value);
      setRecomendedtemplates({ ...obj });
    }
    if (value.length < 3 || value.split(" ").length > 1) {
      setRecomendedtemplates({
        name: "",
        list: [],
      });
    }
    // if (
    //   e.key === "Enter" &&
    //   !e.altKey &&
    //   !e.ctrlKey &&
    //   !e.metaKey &&
    //   !e.shiftKey
    // ) {
    //   e.preventDefault();

    //   handleSend();
    // }
  };

  async function handleBlockUser() {
    try {
      const response = await axios.post(`${api}/block_user/`, {
        chat_session_id: parseInt(chatSessionId),
      });
      UpdateMainData("openBlockUserModal", false);
      UpdateMainData("showtoast", true);
    } catch (e) {
      UpdateMainData("showtoast", false);
      console.log(e);
    }
  }

  useEffect(() => {
    if (chat_data) {
      const startTimestamp = new Date(
        chat_data?.timer_stats?.handover_time
      ).getTime(); // Convert provided time to timestamp
      const currentTimestamp = Date.now(); // Get current time as timestamp
      const delta = Math.floor((currentTimestamp - startTimestamp) / 1000);
      setTimer(delta);
      const updateElapsedTime = () => {
        setTimer((prevTimer) => prevTimer + 1);
      };

      const intervalId = setInterval(updateElapsedTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [chat_data]);

  function getbackgroundColor(timer) {
    if (timer > chat_data?.thresholds?.trt_hard_limit) {
      return "#a60515";
    } else if (timer > chat_data?.thresholds?.trt_soft_limit) {
      return "#c2720a";
    }
    return "#288240";
  }

  const check_last_message = () => {
    if (searchParams.get("ticket_view") === "true") {
      return true;
    }
    if (chat_data?.agent_assistance_required === false) {
      setTimeout(() => {
        if (chatList.length > 0) {
          const option = chatList[0];
          navigate({
            pathname: `/chats/${option?.id}/`,
            search: `?name=${option?.user_name}&user_id=${option?.user_id}&chat_sub_session_id=${option?.chat_sub_session_id}`,
          });
        } else {
          navigate({
            pathname: "/chats/",
            search: "",
          });
        }
      }, 3000);
      return false;
    }
    return true;
  };

  const convert_text = (e) => {
    if (!loader) {
      setLoader(true);
      handleClose();
      axios
        .post(`${api}/ai_inhancement/`, {
          text: chat_text,
          action: e.value,
        })
        .then((res) => {
          setChatText(res.data?.enhanced_text);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  function renderPendingEscalatedCount() {
    const pendingCount = ticketCount?.PENDING ? ticketCount?.PENDING : 0;
    const escalatedCount = ticketCount?.ESCALATED ? ticketCount?.ESCALATED : 0;

    return pendingCount + escalatedCount;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
      className="chat-bot-mobile-main position-relative"
    >
      <div
        className="user-deatils-container flex-space-between-flex-start"
        style={{
          height: "fit-content",
        }}
      >
        <div className="flex-flex-start-flex-start flex-column">
          <div
            className="flex-start"
            style={{
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <div
              className="flex-start"
              style={{
                gap: "10px",
              }}
            >
              {/* <p className="user-name">{` ${searchParams.get(
                "name"
              )} (${chat_user_id})`}</p> */}
              <p className="user-name">
                {searchParams.get("escalated_ticket")
                  ? searchParams.get("name")
                  : ` ${searchParams.get("name")} (${chat_user_id})`}
              </p>
              <a
                target="_blank"
                className="cursor-p"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
                href={`https://cms.smytten.com/UserDetail/${chat_user_id}`}
              >
                <p>
                  <FontAwesomeIcon icon={faSquareArrowUpRight} />
                </p>
              </a>
            </div>
            {
              <div
                className="flex-start"
                style={{
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <p className="user-name">{"Tickets"}</p>

                <p className="open-tickets-ai font-weight-600">
                  {renderPendingEscalatedCount()}
                </p>
                <p className="closed-tickets-ai font-weight-600">
                  {ticketCount?.RESOLVED ? ticketCount?.RESOLVED : 0}
                </p>

                <FontAwesomeIcon
                  icon={faSquareArrowUpRight}
                  onClick={() => {
                    const userId = searchParams.get("user_id");
                    if (searchParams.get("ticket_user_id")) {
                      localStorage.setItem("ticket_sidebar", 1);
                      redirectToPath(`/tickets?ticket_user_id=${userId}`);
                    } else {
                      localStorage.setItem("ticket_sidebar", 2);
                      redirectToPath(`/tickets?user_id=${userId}`);
                    }
                  }}
                  className="cursor-p"
                />
              </div>
            }
            {permissions.includes(MAKE_CALL) && (
              <button
                className="call-user-btn flex-center-center font-weight-600"
                onClick={() => {
                  UpdateMainData("openCallUserModal", true);
                }}
              >
                CALL USER{" "}
                <span className="font-10px">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
              </button>
            )}

            <div className="tool-tip-container">
              <div
                onClick={async () => {
                  UpdateMainData("showTooltip", !data?.showTooltip);
                  await getCallUserData();
                }}
                className="tool-tip-wrapper"
              >
                <Tooltip
                  title={
                    data?.callUserData?.agent ? (
                      callDetailsToolTipContent(data)
                    ) : (
                      <p className="tool-tip-text-ticket flex-center-center flex-column">
                        No data
                      </p>
                    )
                  }
                  placement="top"
                  color={"#166184"}
                  open={data?.showTooltip}
                  // defaultOpen={data?.showTooltip}
                  className="tool-tip-text-container"
                >
                  <p className="info-icon-ticket">
                    {InfoICON("#166184", "white", "32", "32")}
                  </p>
                </Tooltip>
              </div>
            </div>
            {
              <button
                className="call-user-btn flex-center-center font-weight-600"
                onClick={() => {
                  UpdateMainData("openBlockUserModal", true);
                }}
                style={{
                  backgroundColor: "red",
                  marginTop: "-5px",
                }}
              >
                Block User
              </button>
            }
          </div>

          <p>
            <span className="user-time-duration">Chat Duration :</span>
            <span
              className="user-time timer-text-side-chats ml-5px"
              style={{
                backgroundColor: `${getbackgroundColor(timer)}`,
                color: "white",
              }}
            >
              &nbsp;
              {convertToTime(timer)}
            </span>
          </p>
        </div>

        <div className="toggle-row flex-space-between">
          <label className="switch">
            <input
              type="checkbox"
              id="follow-up-toggle"
              checked={toggleOptions}
              onChange={() => setToggleOptions(!toggleOptions)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div
        style={{
          gap: "8px",
          height: can_user_input ? "calc(100% - 267px)" : "calc(100% - 30px)",
          overflowY: "scroll",
        }}
        className="chattting-container position-relative flex-flex-start-flex-start flex-column hide-scroll"
      >
        {chat_loader ? (
          <div className="width-100 height-100 flex-center-center">
            <Spinner animation="border" style={{ color: "#8369fc" }} />
          </div>
        ) : (
          <>
            <ChatInterface
              allParams={{ sender_type: "USER" }}
              r_chat_history={chat_history}
              parentchatContainerRef={parentchatContainerRef}
              bot_type={"ai"}
              toggleOptions={toggleOptions}
            />

            {isUserScrolling && (
              <div
                style={{ bottom: "20px", right: "20px", zIndex: 9 }}
                onClick={() => {
                  scrollChatToBottom(true);
                }}
                className="scroll-down-arrow flex-center-center cursor-p"
              >
                <FontAwesomeIcon
                  style={{ color: "#489cff" }}
                  icon={faArrowDown}
                />
              </div>
            )}
          </>
        )}
      </div>

      {can_user_input &&
        (check_last_message() ? (
          <div className="message-container">
            <div
              style={{
                border: "1px solid #dadada",
                borderBottom: "none",
                padding: "10px",
              }}
              className="flex-space-between-center width-100"
            >
              <div className="top-widgets-text-chatbot ">
                {topWidgetsList
                  ?.filter((e) => {
                    if (
                      e.permission === "ALL" ||
                      e.value !== "image_required"
                    ) {
                      return e;
                    } else if (permissions.includes(e.permission)) {
                      return e;
                    }
                  })
                  ?.map((list, i) => (
                    <p
                      key={i}
                      className="single-widget-chat cursor-p"
                      onClick={() => handleClickWidjet(list)}
                    >
                      {list?.name}
                    </p>
                  ))}
              </div>
              <div
                aria-describedby={id}
                onClick={handleClick}
                className="ai-assistance-box flex-center-center cursor-p"
              >
                Ai Assistance
                {loader && (
                  <div
                    class="spinner-border  ml-10px"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#8639fc",
                    }}
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                className="pop-over-content-container"
              >
                <div className="pop-over-content">
                  {ai_enhancement.map((e, i) => {
                    return (
                      <p
                        onClick={() => {
                          convert_text(e);
                        }}
                        className="mt-5px"
                        key={i}
                      >
                        {e.label}
                      </p>
                    );
                  })}
                </div>
              </Popover>
            </div>

            {/* <textarea
              ref={chatTextArea}
              style={{
                minHeight: "120px",
                borderRadius: "0px 0px 4px 4px",
                outline: "none",
                resize: "none",
                paddingTop: "10px",
              }}
              autoFocus
              className={`color-0F1628 static-border width-100 pl-10px ${
                recomendedTempltes?.list?.length > 0 ? "box-shadow" : ""
              }`}
              placeholder="Enter your message here..."
              value={chat_text}
              onChange={(e) => {
                setChatText(e.target.value);
              }}
              onKeyUp={handleKeyDown}
            /> */}
            <div
              onKeyDownCapture={(e) => {
                if (
                  e.code === "Enter" &&
                  !e.altKey &&
                  !e.ctrlKey &&
                  !e.metaKey &&
                  !e.shiftKey
                ) {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSend();
                }
              }}
            >
              <ReactQuill
                ref={chatTextArea}
                value={chat_text}
                onChange={(value) => {
                  setChatText(value);
                }}
                modules={modules}
                theme="snow"
                className="react-quil-container-custom"
                style={{ height: "120px" }}
                onKeyUp={handleKeyDown}
              />
            </div>
          </div>
        ) : (
          <div className="mt-24px session-closed-strip flex-center-center width-100">
            session closed waiting for feedback
          </div>
        ))}
      {
        <div
          className={`recomendation-template-container box-shadow ${
            recomendedTempltes?.list?.length === 0 ? "fade-out" : "fade-in"
          }`}
          style={{ zIndex: 10 }}
        >
          <div className="recomendation-name-container">Smart Suggestions</div>
          <div className="recomendation-template-list">
            {recomendedTempltes?.list?.map((e, i) => {
              return (
                <div
                  onClick={() => {
                    setChatText(convertTextToHtml(e?.text));
                    if (e?.context?.length) {
                      setSelectionModal(true);
                      setSelectionList(parsevalue(e?.context));
                    }

                    chatTextArea.current.focus();
                    setRecomendedtemplates({
                      name: "",
                      list: [],
                    });
                  }}
                  className="recomendation-single-template cursor-p"
                  key={i}
                >
                  {/* {e?.text} */}
                  {e?.text?.split("\n").map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      }

      {chatmodals?.modal &&
        !["templates", "delegation", "CLOSE_CHAT"].includes(
          chatmodals?.value
        ) && (
          <ChatModal
            show={chatmodals.modal}
            onHide={() => {
              setChatModals((prev) => ({
                modal: false,
                value: "",
                text: "",
              }));
            }}
            title={chatmodals.value}
            setChatText={setChatText}
            apiCall={(message) => {
              sendMessage({
                return_value: message,
                message: message,
                type: chatmodals.value,
              });
            }}
            chatmodals={chatmodals}
          />
        )}

      {chatmodals?.modal &&
        ["templates", "delegation"].includes(chatmodals?.value) && (
          <TemplatesModal
            show={chatmodals?.modal}
            onHide={() => {
              setChatModals((prev) => ({
                modal: false,
                value: "",
                text: "",
              }));
            }}
            title={chatmodals?.value}
            allTemplates={allTemplates}
            apiCall={(message) => {
              // sendMessage(message);
              setChatText(message?.message);
            }}
          />
        )}

      {chatmodals?.modal && ["escalate"].includes(chatmodals?.value) && (
        <EndChatModal
          show={chatmodals.modal}
          onHide={() => {
            setChatModals((prev) => ({
              modal: false,
              value: "",
              text: "",
            }));
          }}
          title={chatmodals.value}
          setChatText={setChatText}
          apiCall={(message) => {
            sendMessage({
              return_value: message,
              message: message,
              type: chatmodals.value,
            });
          }}
          chatmodals={chatmodals}
          chatSessionId={chatSessionId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          escalate_close={true}
          user_order_list={user_order_list}
          chatSubsessionId={chatSubsessionId}
          isShippingList={true}
        />
      )}

      {chatmodals?.modal && ["CLOSE_CHAT"].includes(chatmodals?.value) && (
        <EndChatModal
          show={chatmodals.modal}
          onHide={() => {
            setChatModals((prev) => ({
              modal: false,
              value: "",
              text: "",
            }));
          }}
          title={chatmodals.value}
          setChatText={setChatText}
          apiCall={(message) => {
            sendMessage({
              return_value: message,
              message: message,
              type: chatmodals.value,
            });
          }}
          chatmodals={chatmodals}
          chatSessionId={chatSessionId}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {selection_modal && (
        <SelectionModal
          show={selection_modal}
          onHide={() => {
            setSelectionModal(false);
          }}
          selectionList={selectionList}
          refund_list={refund_list}
          return_request={return_request}
          user_order_list={user_order_list}
          text={chat_text}
          setChatText={setChatText}
        />
      )}

      {data?.openCallUserModal && (
        <CallUserModal
          show={data?.openCallUserModal}
          onHide={() => UpdateMainData("openCallUserModal", false)}
          userId={userId}
          chat={true}
          chatSubsessionId={searchParams.get("chat_sub_session_id")}
          chatSessionId={chatSessionId}
        />
      )}
      {data?.openBlockUserModal && (
        <ConfirmationModal
          show={data?.openBlockUserModal}
          onHide={() => UpdateMainData("openBlockUserModal", false)}
          saveClickHandler={handleBlockUser}
          api={data?.isApi}
          title={"Are you sure to block the user?"}
        />
      )}

      {data?.showtoast && (
        <Toaster messages={["Blocked Successfully"]} show={data?.showtoast} />
      )}
    </div>
  );
}

export default AiAssistance;
